import React from 'react'
import { Cell, Color, ProgressStep, ProgressSteps } from '@revolut/ui-kit'

export interface StepsData {
  color?: Color
  description: string
  title: string
}

interface StepsProps {
  steps: StepsData[]
}

export const Steps = ({ steps }: StepsProps) => {
  return (
    <Cell p={0}>
      <ProgressSteps variant="vertical-compact">
        {steps.map(step => (
          <ProgressStep
            data-testid={`step-${step.color || ''}`}
            indicatorColor={step.color}
            key={step.title}
            state={step.color ? 'done' : 'pending'}
          >
            <ProgressStep.Title>{step.title}</ProgressStep.Title>
            <ProgressStep.Description>{step.description}</ProgressStep.Description>
          </ProgressStep>
        ))}
      </ProgressSteps>
    </Cell>
  )
}
