import { useEffect } from 'react'
import { SESSION_STORAGE } from '@src/constants/api'
import { useQuery } from '@src/utils/queryParamsHooks'

export const useOnboardingPreviewMode = (): {
  cleanup: () => void
  config?: { enable_diversity?: boolean }
} => {
  const { query, deleteQueryParam } = useQuery()

  useEffect(() => {
    if (query.preview) {
      const config: { enable_diversity?: boolean } = {}

      config.enable_diversity = !!query.diversity
      sessionStorage.setItem(SESSION_STORAGE.ONBOARDING_PREVIEW, JSON.stringify(config))

      deleteQueryParam('preview')
      deleteQueryParam('diversity')
    }
  }, [query.preview])

  const config = sessionStorage.getItem(SESSION_STORAGE.ONBOARDING_PREVIEW)
  const parsedConfig = config ? JSON.parse(config) : undefined

  return {
    cleanup: () => sessionStorage.removeItem(SESSION_STORAGE.ONBOARDING_PREVIEW),
    config: parsedConfig,
  }
}
