import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { connect } from 'lape'
import styled from 'styled-components'
import { ActionButton, Box, Flex, Sticky, Token } from '@revolut/ui-kit'
import { ReactComponent as Logo } from '@src/assets/Revolut_People.svg'
import { useGetEmployeeDynamicGroups } from '@src/api/dynamicGroups'
import {
  onboardingBasicInfoStep,
  onboardingDiversityStep,
  onboardingDocumentsStep,
  onboardingPayrollStep,
  onboardingPersonalInfoStep,
  onboardingWelcomeStep,
  useGetOnboardingBasicInfo,
  useGetOnboardingProgress,
} from '@src/api/onboardingV2'
import {
  useGetEmployeeSettings,
  useGetEmployeeOnboardingSettings,
} from '@src/api/settings'
import { SubtabStatus } from '@src/interfaces/data'
import { ROUTES } from '@src/constants/routes'
import PageLoading from '@src/components/PageLoading/PageLoading'
import SideBar from '@src/components/SideBar/SideBar'
import Form from '@src/features/Form/Form'
import { PreTitle } from '@src/features/TabBarNavigation/PreTitle'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import DocumentDocusign from '@src/pages/Forms/DocumentSigning/Docusign'
import DocumentEmbedded from '@src/pages/Forms/DocumentSigning/Embedded'
import DocumentUploadRequest from '@src/pages/Forms/DocumentUpload'
import { logOutAction } from '@src/store/auth/actions'
import { formatWithoutTimezone } from '@src/utils/format'
import { defaultTheme } from '@src/styles/theme'
import { pathToUrl } from '@src/utils/router'
import { useOnboardingPreviewMode } from './common/hooks'
import { OnboardingSteps, TabBarNavigationInterface } from './common/interface'
import {
  areAllStepsComplete,
  getStatus,
  getStepColor,
  getTitle,
  getPreTitle,
} from './common/utils'
import { PageContentWrapper, WrapperCss } from './components/PageContentWrapper'
import { Steps } from './components/Steps'
import { BasicInfo } from './BasicInfo/BasicInfo'
import { Documents } from './Documents/Documents'
import { Diversity } from './Diversity/Diversity'
import { Payroll } from './Payroll/Payroll'
import { PersonalInfo } from './PersonalInfo/PersonalInfo'
import { Start } from './Start/Start'
import { Summary } from './Summary/Summary'

const StyledHeader = styled(Sticky)`
  background-color: ${Token.color.layoutBackground};
  top: 0;
  padding-top: 16px;
  z-index: ${defaultTheme.zIndex.aboveMain};

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    backdrop-filter: blur(16px);
  }
`

export const OnboardingV2 = connect(() => {
  const dispatch = useDispatch()
  const params = useParams<{ id: string; onboardingId: string }>()

  const [progressTimelineOpen, setProgressTimelineOpen] = useState(true)

  const onboardingPreview = useOnboardingPreviewMode()
  const { data: employeeSettings } = useGetEmployeeSettings()
  const { data: employeeOnboardingSettings, isLoading: isLoadingOnboardingSettings } =
    useGetEmployeeOnboardingSettings()
  const { data: employeeDynamicGroups } = useGetEmployeeDynamicGroups(params.id)
  const { data: onboardingBasicInfo, isLoading: isLoadingBasicInfo } =
    useGetOnboardingBasicInfo(params.onboardingId)
  const { data: onboardingProgress, refetch: refetchOnboardingProgress } =
    useGetOnboardingProgress(params.onboardingId)

  const isPreviewEnabled = !!onboardingPreview.config
  const enableBankDetails = employeeSettings?.enable_bank_details
  const enableDiversity = isPreviewEnabled
    ? onboardingPreview.config?.enable_diversity
    : employeeOnboardingSettings?.collect_diversity_information
  const summaryPageMatch = !!useRouteMatch(ROUTES.ONBOARDING_V2.SUMMARY)

  const joiningDateTime = onboardingBasicInfo?.joining_date_time
    ? formatWithoutTimezone(onboardingBasicInfo.joining_date_time)
    : undefined

  const stepsInfo = [
    {
      isCompleted: onboardingProgress?.is_welcome_screen_completed,
      isFocused: !!useRouteMatch(ROUTES.ONBOARDING_V2.START),
      type: OnboardingSteps.start,
    },
    {
      isCompleted: onboardingProgress?.is_basic_info_screen_completed,
      isFocused: !!useRouteMatch(ROUTES.ONBOARDING_V2.BASIC_INFO),
      type: OnboardingSteps.basic_info,
    },
    {
      isCompleted: onboardingProgress?.is_personal_info_screen_completed,
      isFocused: !!useRouteMatch(ROUTES.ONBOARDING_V2.PERSONAL_INFO),
      type: OnboardingSteps.personal_info,
    },
    {
      isCompleted: onboardingProgress?.is_payroll_info_screen_completed,
      isFocused: !!useRouteMatch(ROUTES.ONBOARDING_V2.PAYROLL),
      type: OnboardingSteps.payroll,
    },
    {
      isCompleted: onboardingProgress?.is_diversity_info_screen_completed,
      isFocused: !!useRouteMatch(ROUTES.ONBOARDING_V2.DIVERSITY),
      type: OnboardingSteps.diversity,
    },
    {
      isCompleted: onboardingProgress?.is_documents_info_screen_completed,
      isFocused: !!useRouteMatch(ROUTES.ONBOARDING_V2.DOCUMENTS),
      type: OnboardingSteps.documents,
    },
    {
      isFocused: !!useRouteMatch(ROUTES.ONBOARDING_V2.SUMMARY),
      type: OnboardingSteps.summary,
    },
  ]

  useEffect(() => {
    return onboardingPreview.cleanup
  }, [])

  const allStepsCompleted = areAllStepsComplete(isPreviewEnabled, stepsInfo)

  const getTabActionRoute = (tab: OnboardingSteps, direction: 'prev' | 'next') => {
    const tabIndex = tabs.findIndex(t => t.id === tab)

    if (tabIndex === -1) {
      return undefined
    }

    const prevTab = tabs[tabIndex - 1]
    const nextTab = tabs[tabIndex + 1]

    return direction === 'next' ? nextTab?.to : prevTab?.to
  }

  const tabs = [
    {
      id: OnboardingSteps.start,
      path: ROUTES.ONBOARDING_V2.START,
      to: pathToUrl(ROUTES.ONBOARDING_V2.START, params),
      title: getTitle('Start'),
      preTitle: getPreTitle(isPreviewEnabled, stepsInfo, OnboardingSteps.start),
      status: getStatus(isPreviewEnabled, stepsInfo, OnboardingSteps.start),
      component: Start,
      api: onboardingWelcomeStep,
    },
    {
      id: OnboardingSteps.basic_info,
      path: ROUTES.ONBOARDING_V2.BASIC_INFO,
      to: pathToUrl(ROUTES.ONBOARDING_V2.BASIC_INFO, params),
      title: getTitle('Basic info'),
      preTitle: getPreTitle(isPreviewEnabled, stepsInfo, OnboardingSteps.basic_info),
      status: getStatus(isPreviewEnabled, stepsInfo, OnboardingSteps.basic_info),
      component: BasicInfo,
      api: onboardingBasicInfoStep,
    },
    {
      id: OnboardingSteps.personal_info,
      path: ROUTES.ONBOARDING_V2.PERSONAL_INFO,
      to: pathToUrl(ROUTES.ONBOARDING_V2.PERSONAL_INFO, params),
      title: getTitle('Personal info'),
      preTitle: getPreTitle(isPreviewEnabled, stepsInfo, OnboardingSteps.personal_info),
      status: getStatus(isPreviewEnabled, stepsInfo, OnboardingSteps.personal_info),
      component: PersonalInfo,
      api: onboardingPersonalInfoStep,
    },
    enableBankDetails
      ? {
          id: OnboardingSteps.payroll,
          path: ROUTES.ONBOARDING_V2.PAYROLL,
          to: pathToUrl(ROUTES.ONBOARDING_V2.PAYROLL, params),
          title: getTitle('Payroll'),
          preTitle: getPreTitle(isPreviewEnabled, stepsInfo, OnboardingSteps.payroll),
          status: getStatus(isPreviewEnabled, stepsInfo, OnboardingSteps.payroll),
          component: Payroll,
          api: onboardingPayrollStep,
        }
      : null,
    enableDiversity
      ? {
          id: OnboardingSteps.diversity,
          path: ROUTES.ONBOARDING_V2.DIVERSITY,
          to: pathToUrl(ROUTES.ONBOARDING_V2.DIVERSITY, params),
          title: getTitle('Diversity'),
          preTitle: getPreTitle(isPreviewEnabled, stepsInfo, OnboardingSteps.diversity),
          status: getStatus(isPreviewEnabled, stepsInfo, OnboardingSteps.diversity),
          component: Diversity,
          api: onboardingDiversityStep,
        }
      : null,
    {
      id: OnboardingSteps.documents,
      path: ROUTES.ONBOARDING_V2.DOCUMENTS,
      to: pathToUrl(ROUTES.ONBOARDING_V2.DOCUMENTS, params),
      title: getTitle('Documents'),
      preTitle: getPreTitle(isPreviewEnabled, stepsInfo, OnboardingSteps.documents),
      status: getStatus(isPreviewEnabled, stepsInfo, OnboardingSteps.documents),
      component: Documents,
      api: onboardingDocumentsStep,
    },
  ].filter(Boolean)

  const tabBarTabs: TabBarNavigationInterface[] = [
    ...tabs,
    {
      id: OnboardingSteps.summary,
      path: ROUTES.ONBOARDING_V2.SUMMARY,
      to: pathToUrl(ROUTES.ONBOARDING_V2.SUMMARY, params),
      title: getTitle('Summary'),
      preTitle: <PreTitle complete={allStepsCompleted} />,
    },
  ]

  const stepsData = [
    {
      title: 'Start',
      description: 'Introduction to your onboarding process.',
      color: getStepColor(isPreviewEnabled, stepsInfo, OnboardingSteps.start),
    },
    {
      title: 'Basic information',
      description: 'Verify basic information and choose your work email address.',
      color: getStepColor(isPreviewEnabled, stepsInfo, OnboardingSteps.basic_info),
    },
    {
      title: 'Personal information',
      description: 'Provide necessary data.',
      color: getStepColor(isPreviewEnabled, stepsInfo, OnboardingSteps.personal_info),
    },
    enableBankDetails
      ? {
          title: 'Payroll',
          description: 'Enter your bank account details.',
          color: getStepColor(isPreviewEnabled, stepsInfo, OnboardingSteps.payroll),
        }
      : null,
    enableDiversity
      ? {
          title: 'Diversity',
          description: 'If you wish, share with us more information about you.',
          color: getStepColor(isPreviewEnabled, stepsInfo, OnboardingSteps.diversity),
        }
      : null,
    {
      title: 'Documents',
      description: 'Upload and sign documents to finalise your onboarding.',
      color: getStepColor(isPreviewEnabled, stepsInfo, OnboardingSteps.documents),
    },
  ].filter(Boolean)

  if (!onboardingBasicInfo) {
    return (
      <Box height="100vh" width="100vw">
        <PageLoading />
      </Box>
    )
  }

  return (
    <>
      <Flex flexDirection="column" minHeight="100vh" width="100%">
        <Switch>
          <Route exact path={ROUTES.ONBOARDING_V2.DOCUMENT_DOCUSIGN}>
            <PageContentWrapper>
              <DocumentDocusign />
            </PageContentWrapper>
          </Route>
          <Route exact path={ROUTES.ONBOARDING_V2.DOCUMENT_EMBEDDED}>
            <PageContentWrapper>
              <DocumentEmbedded />
            </PageContentWrapper>
          </Route>
          <Route exact path={ROUTES.ONBOARDING_V2.DOCUMENT_UPLOAD_REQUEST}>
            <PageContentWrapper>
              <DocumentUploadRequest />
            </PageContentWrapper>
          </Route>

          <Route exact path={ROUTES.ONBOARDING_V2.ANY}>
            <StyledHeader>
              <Flex css={WrapperCss} flexDirection="column" pb="s-12" pt="s-48">
                <Flex justifyContent="space-between" mb="s-8">
                  <Logo height="20" />
                  <Flex gap="s-8">
                    {summaryPageMatch ? null : (
                      <ActionButton
                        onClick={() => setProgressTimelineOpen(true)}
                        useIcon="Time"
                      >
                        Progress timeline
                      </ActionButton>
                    )}
                    <ActionButton
                      onClick={() => dispatch(logOutAction())}
                      useIcon="LogoutDoor"
                    >
                      Sign out
                    </ActionButton>
                  </Flex>
                </Flex>
                <TabBarNavigation tabs={tabBarTabs} py="s-12" />
              </Flex>
            </StyledHeader>
            <Switch>
              {tabs.map(tab => (
                <Route exact path={tab.path} key={tab.path}>
                  <PageContentWrapper>
                    <Form api={isPreviewEnabled ? undefined : tab.api} key={tab.path}>
                      <tab.component
                        dynamicGroups={employeeDynamicGroups?.results}
                        joiningDateTime={joiningDateTime}
                        isCompleted={tab.status === SubtabStatus.Completed}
                        isLoadingBasicInfo={isLoadingBasicInfo}
                        isLoadingOnboardingSettings={isLoadingOnboardingSettings}
                        isPreview={isPreviewEnabled}
                        name={onboardingBasicInfo.display_name}
                        onboardingSettings={employeeOnboardingSettings}
                        onComplete={
                          isPreviewEnabled ? undefined : refetchOnboardingProgress
                        }
                        nextRoute={getTabActionRoute(tab.id, 'next')}
                        prevRoute={getTabActionRoute(tab.id, 'prev')}
                      />
                    </Form>
                  </PageContentWrapper>
                </Route>
              ))}
            </Switch>

            <Route exact path={ROUTES.ONBOARDING_V2.SUMMARY}>
              <Summary
                allStepsCompleted={allStepsCompleted}
                joiningDateTime={joiningDateTime}
                landingPageUrl={employeeOnboardingSettings?.redirect_url_on_completion}
                steps={stepsData}
              />
            </Route>
          </Route>
        </Switch>
      </Flex>

      {onboardingProgress && !summaryPageMatch ? (
        <SideBar
          data-testid="progress-timeline"
          isOpen={progressTimelineOpen}
          onClose={() => setProgressTimelineOpen(false)}
          subtitle={
            joiningDateTime ? `Expected joining date: ${joiningDateTime}` : undefined
          }
          title="Onboarding progress timeline"
        >
          <Steps steps={stepsData} />
        </SideBar>
      ) : null}
    </>
  )
})
