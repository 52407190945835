import React from 'react'
import { Side } from '@revolut/ui-kit'
import { employeeEmailsRequests, getPrefilledTemplate } from '@src/api/employeeEmails'
import SideBar from '@components/SideBar/SideBar'
import Form from '@src/features/Form/Form'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { EmployeeUserWithAvatar } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import SendEmployeeEmailCommon from '@src/pages/Forms/SendEmployeeEmail/SendEmployeeEmailCommon'

type Props = {
  isOpen: boolean
  employeeId: number | string
  onClose: () => void
  onRefetch?: () => void
}

export const SendEmailSidebar = ({ employeeId, isOpen, onClose, onRefetch }: Props) => {
  const fetchEmail = (templateId: number) => {
    return getPrefilledTemplate(templateId, +employeeId)
  }

  return (
    <SideBar
      isOpen={isOpen}
      onClose={onClose}
      subtitle={<EmployeeUserWithAvatar id={employeeId} />}
      title="Send email"
    >
      <Form
        api={employeeEmailsRequests}
        disableLocalStorageCaching
        forceParams={{ employeeId: String(employeeId), new: 'new' }}
      >
        <SendEmployeeEmailCommon insideSidebar fetchEmail={fetchEmail} />
        <Side.Actions>
          <NewSaveButtonWithPopup
            hideWhenNoChanges={false}
            onAfterSubmit={() => {
              onRefetch?.()
              onClose()
            }}
            successText="Email has been sent"
            useValidator
          >
            Send email
          </NewSaveButtonWithPopup>
        </Side.Actions>
      </Form>
    </SideBar>
  )
}
