import React, { useEffect } from 'react'
import { subYears } from 'date-fns'
import { Box, InputGroup, Token } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { selectorKeys } from '@src/constants/api'
import { SectionOptions } from '@src/interfaces/customFields'
import {
  EMPLOYEE_PROFILE_SUB_SECTIONS,
  PersonalInfoInterface,
} from '@src/interfaces/employees'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { LapePhoneInput } from '@components/Inputs/LapeFields/LapePhoneInput'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperSectionCustomFields from '@components/Stepper/NewStepperSectionCustomFields'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  addressLine1Message,
  addressLine2Message,
  addressLine3Message,
  cityMessage,
} from '@src/pages/EmployeeProfile/Forms/constants'
import { LaptopDeliveryInputs } from '@src/pages/EmployeeProfile/Forms/LaptopDelivery/LaptopDeliveryInputs'

import { TabProps } from '../common/interface'
import { BackButton, NextButton } from '../components/Buttons'
import { PageHeader } from '../components/PageHeader'

const DATE_OF_BIRTH_YEAR_LIMIT = 13

export const PersonalInfo = ({
  dynamicGroups,
  nextRoute,
  onboardingSettings,
  onComplete,
  prevRoute,
}: TabProps) => {
  const { values } = useLapeContext<PersonalInfoInterface>()

  const dateOfBirthLimit = subYears(new Date(), DATE_OF_BIRTH_YEAR_LIMIT)

  const contactHREmail = onboardingSettings?.contact_hr_email
  const enableEquipmentDelivery =
    onboardingSettings?.collect_equipment_shipment_information

  useEffect(() => {
    // TODO: delete when refactored on BE
    if (values.phone_number) {
      values.phone_number = undefined
    }
  }, [])

  useEffect(() => {
    if (!enableEquipmentDelivery) {
      values.laptop_delivery = undefined
    }
  }, [enableEquipmentDelivery])

  const navigateToPrevStep = () => {
    if (prevRoute) {
      navigateTo(prevRoute)
    }
  }

  const navigateToNextStep = () => {
    if (nextRoute) {
      navigateTo(nextRoute)
      onComplete?.()
    }
  }

  return (
    <>
      <PageBody>
        <AutoStepper pb="s-24">
          <PageHeader email={contactHREmail} title="Personal information" />

          <NewStepperTitle title="Bio" />
          <NewStepperSectionCustomFields
            dynamicGroups={dynamicGroups}
            sectionId={SectionOptions.EmployeeProfile}
            subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.BIO}
            useExternalRequests
          >
            <InputGroup>
              <LapeRadioSelectInput
                name="legal_sex"
                label="Legal sex"
                selector={selectorKeys.legal_sexes}
              />
              <LapeNewMultiSelect
                name="nationalities"
                placeholder="Nationalities"
                selector={selectorKeys.countries}
                hideOptional
              />
              <LapeDatePickerInput
                name="birth_date"
                label="Date of birth"
                disabledDays={{ after: dateOfBirthLimit }}
                initialMonth={dateOfBirthLimit}
                required
              />
              <LapeRadioSelectInput
                name="marital_status"
                label="Marital status"
                selector={selectorKeys.marital_statuses}
              />
              <LapeNewMultiSelect
                name="languages"
                placeholder="Languages"
                selector={selectorKeys.language}
                hideOptional
              />
            </InputGroup>
          </NewStepperSectionCustomFields>

          <NewStepperSectionCustomFields
            dynamicGroups={dynamicGroups}
            sectionId={SectionOptions.EmployeeProfile}
            subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.CONTACT_INFO}
            useExternalRequests
          >
            <NewStepperTitle title="Address" />
            <InputGroup>
              <LapeNewInput
                label="Address Line 1"
                name="address_line_1"
                description={addressLine1Message}
                hideOptional
              />
              <LapeNewInput
                label="Address line 2"
                name="address_line_2"
                description={addressLine2Message}
                hideOptional
              />
              <LapeNewInput
                label="Address line 3"
                name="address_line_3"
                description={addressLine3Message}
              />
              <InputGroup variant="horizontal">
                <Box flex="1">
                  <LapeNewInput
                    label="City"
                    name="city"
                    description={cityMessage}
                    hideOptional
                  />
                </Box>
                <Box flex="1">
                  <LapeNewInput label="ZIP code" name="post_code" hideOptional />
                </Box>
              </InputGroup>

              <InputGroup variant="horizontal">
                <Box flex="1">
                  <LapeNewInput name="county" label="County" />
                </Box>
                <Box flex="1">
                  <LapeRadioSelectInput
                    selector={selectorKeys.countries}
                    name="country"
                    label="Country"
                  />
                </Box>
              </InputGroup>
            </InputGroup>
          </NewStepperSectionCustomFields>

          {enableEquipmentDelivery ? <LaptopDeliveryInputs withStepperTitle /> : null}

          <NewStepperTitle title="Emergency contact" />
          <InputGroup>
            <LapeNewInput
              label="Full name"
              name="emergency_contact_full_name"
              hideOptional
            />
            <LapeNewInput
              label="Relationship"
              name="emergency_contact_relationship"
              hideOptional
            />
            <LapeNewInput name="emergency_contact_email" label="Email" hideOptional />
            <LapePhoneInput
              message="Please provide emergency contact mobile number"
              phoneName="emergency_contact_phone_number"
              prefixName="emergency_contact_phone_country_code"
              required
            />
          </InputGroup>

          <NewStepperSectionCustomFields
            customFieldsOnly
            dynamicGroups={dynamicGroups}
            sectionId={SectionOptions.EmployeeProfile}
            subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.CORRESPONDENCE_ADDRESS}
            useExternalRequests
          >
            <NewStepperTitle title="Correspondence address" />
          </NewStepperSectionCustomFields>

          <NewStepperSectionCustomFields
            customFieldsOnly
            dynamicGroups={dynamicGroups}
            sectionId={SectionOptions.EmployeeProfile}
            subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.IDENTIFICATION_DETAILS}
            useExternalRequests
          >
            <NewStepperTitle title="Identification details" />
          </NewStepperSectionCustomFields>

          <NewStepperSectionCustomFields
            dynamicGroups={dynamicGroups}
            sectionId={SectionOptions.EmployeeProfile}
            subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.ABOUT_YOU}
            useExternalRequests
          >
            <NewStepperTitle
              title="About you"
              subtitle="You can write a few things about yourself e.g. hobbies, fun facts, or any other things you would like to share."
            />
            <InputGroup>
              <LapeNewTextArea label="About" name="about" rows={3} />
            </InputGroup>
          </NewStepperSectionCustomFields>
        </AutoStepper>
      </PageBody>

      <PageActions alignSelf="center" maxWidthMd={Token.breakpoint.sm}>
        <BackButton onClick={navigateToPrevStep} />
        <NextButton afterSubmit={navigateToNextStep} />
      </PageActions>
    </>
  )
}
