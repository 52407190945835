import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { ActionButton, Avatar, Group, Item, Token, VStack } from '@revolut/ui-kit'
import { GetRequestData } from '@src/interfaces'
import { AttachedTemplateInterface } from '@src/interfaces/documentsTemplates'
import {
  addDocumentSignature,
  addDocumentUpload,
  useTasks,
} from '@src/api/onboardingEmployeesV2'
import {
  OnboardingTaskInterface,
  OnboardingTemplateInterface,
  OnboardingStatus,
} from '@src/interfaces/onboardingV2'
import { SelectTemplatesSidebar } from '@src/apps/People/Documents/BulkRequest/Edit/components/SelectTemplatesSidebar'
import { ScreeningCheck } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/components/ScreeningCheck'
import { RightToWork } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/components/RightToWork'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { PreviewTemplateSidebar } from './PreviewTemplateSidebar'
import { TaskItem } from './TaskItem'
import { TemplateItem } from './TemplateItem'
import { TaskSidebar } from './TaskSidebar'

type Props = {
  documentsSignature?: GetRequestData<OnboardingTemplateInterface>
  documentsUpload?: GetRequestData<OnboardingTemplateInterface>
  onRefetchDocumentsSignature: VoidFunction
  onRefetchDocumentsUpload: VoidFunction
  status: OnboardingStatus
}

export const OnboardingInformation = ({
  documentsSignature,
  documentsUpload,
  onRefetchDocumentsSignature,
  onRefetchDocumentsUpload,
  status,
}: Props) => {
  const showStatusPopup = useShowStatusPopup()
  const { id, onboardingId } = useParams<{ id: string; onboardingId: string }>()
  const [task, setTask] = useState<OnboardingTaskInterface>()
  const [taskSideBarOpen, setTaskSiderOpen] = useState(false)
  const [templatesSidebar, setTemplatesSidebar] = useState<'request' | 'esignature'>()
  const [templatePreview, setTemplatePreview] = useState<OnboardingTemplateInterface>()
  const { data: tasksData, refetch: refetchTasksData } = useTasks(onboardingId)

  const attachedTemplates =
    (templatesSidebar && templatesSidebar === 'request'
      ? documentsUpload
      : documentsSignature
    )?.results.map(template => ({
      id: template.document_template.id,
      name: template.document_template.name,
    })) || []

  const handleDocumentUpload = async (templateId: number) => {
    try {
      await addDocumentUpload(onboardingId, templateId)
      onRefetchDocumentsUpload()
    } catch (err) {
      showStatusPopup({
        title: 'Failed to add new document',
        description: getStringMessageFromError(err),
        status: 'error',
      })
    }
  }

  const handleDocumentSignature = async (templateId: number) => {
    try {
      await addDocumentSignature(onboardingId, templateId)
      onRefetchDocumentsSignature()
    } catch (err) {
      showStatusPopup({
        title: 'Failed to add new document',
        description: getStringMessageFromError(err),
        status: 'error',
      })
    }
  }

  const handleAddTemplate = (newTemplate: AttachedTemplateInterface) => {
    templatesSidebar === 'request'
      ? handleDocumentUpload(newTemplate.id)
      : handleDocumentSignature(newTemplate.id)
  }

  return (
    <>
      <VStack gap="s-16">
        <Group>
          <Item>
            <Item.Content>
              <Item.Title color={Token.color.greyTone50}>
                Documents to be uploaded
              </Item.Title>
            </Item.Content>
            <Item.Side>
              <ActionButton onClick={() => setTemplatesSidebar('request')} useIcon="Plus">
                Add new
              </ActionButton>
            </Item.Side>
          </Item>
          {!!documentsUpload?.results.length && (
            <VStack>
              {documentsUpload.results.map(documentUpload => (
                <TemplateItem
                  key={documentUpload.id}
                  status={status}
                  template={documentUpload}
                  handleClick={() => setTemplatePreview(documentUpload)}
                />
              ))}
            </VStack>
          )}
          {!documentsUpload?.results.length && (
            <Item>
              <Item.Avatar>
                <Avatar color={Token.color.greyTone20} useIcon="Document" />
              </Item.Avatar>
              <Item.Content>
                <Item.Title color={Token.color.greyTone50}>
                  Nothing requested yet
                </Item.Title>
              </Item.Content>
            </Item>
          )}
        </Group>

        <Group>
          <Item>
            <Item.Content>
              <Item.Title color={Token.color.greyTone50}>
                Documents to be signed
              </Item.Title>
            </Item.Content>
            <Item.Side>
              <ActionButton
                onClick={() => setTemplatesSidebar('esignature')}
                useIcon="Plus"
              >
                Add new
              </ActionButton>
            </Item.Side>
          </Item>
          {!!documentsSignature?.results.length && (
            <VStack>
              {documentsSignature.results.map(documentSignature => (
                <TemplateItem
                  key={documentSignature.id}
                  status={status}
                  template={documentSignature}
                  handleClick={() => setTemplatePreview(documentSignature)}
                />
              ))}
            </VStack>
          )}
          {!documentsSignature?.results.length && (
            <Item>
              <Item.Avatar>
                <Avatar color={Token.color.greyTone20} useIcon="Pencil" />
              </Item.Avatar>
              <Item.Content>
                <Item.Title color={Token.color.greyTone50}>
                  Nothing requested yet
                </Item.Title>
              </Item.Content>
            </Item>
          )}
        </Group>

        <Group>
          <Item>
            <Item.Content>
              <Item.Title color={Token.color.greyTone50}>Tasks</Item.Title>
            </Item.Content>
            <Item.Side>
              <ActionButton onClick={() => setTaskSiderOpen(true)} useIcon="Plus">
                Add new
              </ActionButton>
            </Item.Side>
          </Item>
          {!!tasksData?.results.length && (
            <VStack>
              {tasksData.results.map(taskItem => (
                <TaskItem
                  key={taskItem.id}
                  task={taskItem}
                  handleClick={() => {
                    setTaskSiderOpen(true)
                    setTask(taskItem)
                  }}
                />
              ))}
            </VStack>
          )}
          {!tasksData?.results.length && (
            <Item>
              <Item.Avatar>
                <Avatar color={Token.color.greyTone20} useIcon="Form" />
              </Item.Avatar>
              <Item.Content>
                <Item.Title color={Token.color.greyTone50}>Nothing added yet</Item.Title>
              </Item.Content>
            </Item>
          )}
        </Group>

        <ScreeningCheck employeeId={Number(id)} hideTableRoute />
        <RightToWork employeeId={Number(id)} hideTableRoute />
      </VStack>

      {!!templatesSidebar && (
        <SelectTemplatesSidebar
          allowCreate={templatesSidebar === 'request'}
          attachedTemplates={attachedTemplates}
          filterByTypes={[templatesSidebar]}
          isOpen={!!templatesSidebar}
          onAddTemplate={newTemplate => handleAddTemplate(newTemplate)}
          onClose={() => setTemplatesSidebar(undefined)}
        />
      )}

      <PreviewTemplateSidebar
        document={templatePreview}
        employeeId={id}
        onClose={() => setTemplatePreview(undefined)}
        onboardingId={onboardingId}
        onboardingStatus={status}
        onRefetchDocumentsSignature={onRefetchDocumentsSignature}
        onRefetchDocumentsUpload={onRefetchDocumentsUpload}
      />

      <TaskSidebar
        isOpen={taskSideBarOpen}
        onClose={() => {
          setTaskSiderOpen(false)
          setTask(undefined)
          refetchTasksData()
        }}
        onboardingId={onboardingId}
        refetchTasksData={refetchTasksData}
        task={task}
      />
    </>
  )
}
