import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import {
  AvailableInterviewerSlot,
  InterviewStageWithoutRoundInterface,
} from '@src/interfaces/interviewTool'
import { IdAndName } from '@src/interfaces'
import add from 'date-fns/add'
import { addWeeks, Duration, previousMonday } from 'date-fns'
import { getDateFromEventDate } from '@src/pages/Forms/Candidate/ScheduleInterview/utils'

export interface CalendarEventInterface {
  start: Date
  end: Date
}

export type CurrentWeekType = {
  start: Date
  end: Date
}

export interface ScheduleInterviewContextInterface {
  interviewer?: EmployeeOptionInterface
  setInterviewer: (interviewer: EmployeeOptionInterface) => void
  additionalInterviewers?: EmployeeOptionInterface[]
  setAdditionalInterviewers: (interviewers: EmployeeOptionInterface[]) => void
  duration?: number
  setDuration: (duration: number) => void
  interviewStage?: InterviewStageWithoutRoundInterface
  setInterviewStage: (stage: InterviewStageWithoutRoundInterface) => void
  durationUnit?: IdAndName<string>
  setDurationUnit: (unit: IdAndName<string>) => void
  calendarEvent?: CalendarEventInterface
  setCalendarEvent: (params: { startDate: Date } | undefined) => void
  timeZone?: IdAndName<string>
  setTimeZone: (timeZone: IdAndName<string>) => void
  disabledCalendar?: boolean
  setDisabledCalendar: (disabledCalendar: boolean) => void
  selectedSlots: AvailableInterviewerSlot[]
  setSelectedSlots: (selectedSlots: AvailableInterviewerSlot[]) => void
  currentWeek: CurrentWeekType
  setCurrentWeek: (currentWeekRange: CurrentWeekType) => void
}

const getCurrentWeekRange = (): CurrentWeekType => ({
  start: previousMonday(new Date()),
  end: addWeeks(previousMonday(new Date()), 1),
})

const ScheduleInterviewContext =
  React.createContext<ScheduleInterviewContextInterface | null>(null)

type Props = {
  children: React.ReactNode
}

export const ScheduleInterviewProvider = ({ children }: Props) => {
  const [interviewer, setInterviewer] = useState<EmployeeOptionInterface>()
  const [additionalInterviewers, setAdditionalInterviewers] =
    useState<EmployeeOptionInterface[]>()
  const [duration, setDuration] = useState<number>()
  const [durationUnit, setDurationUnit] = useState<IdAndName<string>>()
  const [interviewStage, setInterviewStage] =
    useState<InterviewStageWithoutRoundInterface>()
  const [calendarEvent, setFormattedCalendarEvent] = useState<CalendarEventInterface>()
  const [timeZone, setTimeZone] = useState<IdAndName<string>>()
  const [disabledCalendar, setDisabledCalendar] = useState(false)
  const [selectedSlots, setSelectedSlots] = useState<AvailableInterviewerSlot[]>([])
  const [currentWeek, setCurrentWeek] = useState<CurrentWeekType>(getCurrentWeekRange())

  const setCalendarEvent = useCallback<
    ScheduleInterviewContextInterface['setCalendarEvent']
  >(
    params => {
      let options: Duration = {}

      if (!durationUnit?.id || !duration) {
        return
      }

      switch (durationUnit.id) {
        case 'minutes':
          options = {
            minutes: duration,
          }
          break

        case 'hours':
          options = {
            hours: duration,
          }
          break

        case 'days':
          options = {
            days: duration,
          }
          break
      }

      if (!params) {
        setFormattedCalendarEvent(undefined)
        return
      }

      setFormattedCalendarEvent({
        start: params.startDate,
        end: add(params.startDate, options),
      })
    },
    [duration, durationUnit],
  )

  useEffect(() => {
    const startDate = getDateFromEventDate(calendarEvent?.start)

    if (startDate) {
      setCalendarEvent({ startDate })
    }
  }, [duration, durationUnit])

  const value = useMemo(
    () => ({
      interviewer,
      setInterviewer,
      additionalInterviewers,
      setAdditionalInterviewers,
      duration,
      setDuration,
      interviewStage,
      setInterviewStage,
      durationUnit,
      setDurationUnit,
      calendarEvent,
      setCalendarEvent,
      timeZone,
      setTimeZone,
      disabledCalendar,
      setDisabledCalendar,
      selectedSlots,
      setSelectedSlots,
      currentWeek,
      setCurrentWeek,
    }),
    [
      interviewer,
      setInterviewer,
      additionalInterviewers,
      setAdditionalInterviewers,
      duration,
      setDuration,
      interviewStage,
      setInterviewStage,
      durationUnit,
      setDurationUnit,
      calendarEvent,
      setCalendarEvent,
      timeZone,
      setTimeZone,
      disabledCalendar,
      setDisabledCalendar,
      selectedSlots,
      setSelectedSlots,
      currentWeek,
      setCurrentWeek,
    ],
  )

  return (
    <ScheduleInterviewContext.Provider value={value}>
      {children}
    </ScheduleInterviewContext.Provider>
  )
}

export const useScheduleInterviewContext = () => {
  const context = useContext(ScheduleInterviewContext)

  if (context == null) {
    throw new Error(
      `useScheduleInterviewContext must be used within a ScheduleInterviewContext`,
    )
  }

  return context
}
