import React, { CSSProperties, useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import {
  ColumnCellInterface,
  FilterByInterface,
  RowInterface,
} from '@src/interfaces/data'
import { Cell } from './Cell'
import Icon from '../Icon/Icon'
import { generateKey } from '@src/utils/table'
import { Box, Sticky, Token, Icon as UIKitIcon, opacity } from '@revolut/ui-kit'
import { SelectContext } from '@components/TableV2/AdvancedCells/SelectCell/SelectTableWrapper'
import { RowHeight, TableTypes } from '@src/interfaces/table'
import { FormError } from '@src/features/Form/LapeForm'
import { defaultTheme } from '@src/styles/theme'

interface Props<T> {
  onRowClick?: (data: T, parentIndexes: number[]) => void
  nestedFirst?: boolean
  nestedLast?: boolean
  fetchChildren?: (parents: number[], id: number | string) => Promise<any>
  onFilterChange?: (filters: FilterByInterface) => void
  noChildrenRequest?: boolean | ((data: T) => boolean)
  row: RowInterface<T>
  cellErrors?: (FormError<T> | undefined)[]
  forceRender: () => void
  data: T
  noAutoResize?: boolean
  parentIndexes: number[]
  visibleCells: ColumnCellInterface<T>[]
  type: TableTypes
  style?: CSSProperties
  idPath: string
  childrenAlwaysOpen?: boolean
  childrenOpenByDefault?: boolean
  expandableType?: 'chevron' | 'plus'
  containerWidth?: number
  rowHeight: RowHeight
  useFetchedChildren?: boolean
  alterBackground?: boolean
  isExpandable?: boolean
}

const RowMatrixTableCss = css`
  border-left: 1px solid #e8ebef;
  min-height: 50px;
  cursor: auto;

  &:hover:after {
    display: none;
  }
`

const RowHoverNoColorCss = css`
  &:hover {
    background-color: ${Token.color.greyTone2};
  }
`

const RowHoverWithColorCss = css`
  &:hover:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: ${defaultTheme.zIndex.aboveMain + 1};
    width: 100%;
    height: 100%;
    content: '';
    background-color: ${Token.color.greyTone50};
    pointer-events: none;
    opacity: 0.02;
  }
`

export const rowWrapperMinHeight = {
  small: 32,
  medium: 40,
  large: 72,
}

const RowWrapper = styled.div<{
  backgroundColor: string | null
  nestedFirst?: boolean
  nestedLast?: boolean
  type: TableTypes
  rowHeight: RowHeight
  hasRowAction?: boolean
  highlightRow?: boolean
}>`
  background-color: ${props => props.backgroundColor || Token.color.widgetBackground};
  position: relative;
  cursor: ${props => (props.hasRowAction ? 'pointer' : 'default')};
  display: flex;
  min-height: ${({ rowHeight }) => `${rowWrapperMinHeight[rowHeight]}px`};
  width: max-content;
  min-width: 100%;
  ${({ type }) => type === TableTypes.Matrix && RowMatrixTableCss}
  ${props => (props.backgroundColor ? RowHoverWithColorCss : RowHoverNoColorCss)}
`

const LoadingRow = styled.div<{
  backgroundColor: string
  nestedFirst: boolean
  nestedLast: boolean
  level: number
  containerWidth?: number
  rowHeight: RowHeight
}>`
  border-bottom: 1px solid ${Token.color.greyTone8};
  background-color: ${props => props.backgroundColor};
  position: relative;
  cursor: pointer;
  display: flex;
  min-height: ${({ rowHeight }) => `${rowWrapperMinHeight[rowHeight]}px`};
  align-items: center;
  width: ${props => (props.containerWidth ? `${props.containerWidth}px` : '100%')};
`

const OpenIcon = styled.div`
  color: ${Token.color.greyTone50};

  &:hover {
    color: ${Token.color.blue};
  }
`
const DropdownWrapper = styled.div<{ level: number }>`
  padding-left: ${props => props.level * 16 + 16}px;
  padding-right: 8px;
  display: flex;
  align-items: center;
`
const EmptyWrapper = styled.div<{ level: number }>`
  padding-left: ${props => props.level * 16 + 16 + 16}px;
  padding-right: 8px;
  display: flex;
  align-items: center;
`

const Row = <T extends { children?: T[]; id?: number | string }>({
  data,
  row,
  onRowClick,
  onFilterChange,
  noChildrenRequest,
  forceRender,
  cellErrors,
  fetchChildren,
  noAutoResize,
  nestedFirst,
  nestedLast,
  parentIndexes,
  visibleCells,
  type,
  style = {},
  idPath,
  childrenAlwaysOpen = false,
  expandableType = 'plus',
  containerWidth,
  rowHeight,
  useFetchedChildren = false,
  childrenOpenByDefault = false,
  alterBackground = false,
  isExpandable = false,
}: Props<T>) => {
  const selectionContext = useContext(SelectContext)
  const level = parentIndexes.length - 1
  const isNested = data.children && !row.isNotNested
  const [open, setOpen] = useState(false)
  const [hasFetchedData, setHasFetchedData] = useState(false)

  const [children, setChildren] = useState<T[]>([])

  const rowId = get(data, idPath)
  let backgroundColor = null

  const selected =
    selectionContext?.selectedRowsData.has(String(rowId)) ||
    (selectionContext?.isAllSelected && !selectionContext?.excludeList.has(String(rowId)))

  if (row.highlight && row.highlight(data)) {
    backgroundColor = row.highlight(data)
  } else if (selected) {
    backgroundColor = Token.color.actionBackground
  } else if (level === 0 && childrenAlwaysOpen) {
    backgroundColor = Token.color.greyTone2
  }

  const iconType = data?.children?.length === 0 ? 'Dot' : open ? 'Minus' : 'Plus'

  const renderIconTypeChevron = () => {
    if (data?.children?.length === 0) {
      return (
        <OpenIcon>
          <Icon type="Dot" size="tiny" />
        </OpenIcon>
      )
    }
    if (open) {
      return (
        <UIKitIcon name="16/ChevronDownSmall" color={Token.color.greyTone50} size={14} />
      )
    }
    return <UIKitIcon name="ChevronRight" color={Token.color.greyTone50} size={16} />
  }

  const isUnwrapIconShown = isNested && !childrenAlwaysOpen
  const isEmptyWrapperShown = (!isNested && level > 0) || childrenAlwaysOpen

  const unwrapChildren = (e?: React.MouseEvent) => {
    forceRender()
    e?.preventDefault()
    e?.stopPropagation()

    const noRequest =
      typeof noChildrenRequest === 'function'
        ? noChildrenRequest(data)
        : noChildrenRequest

    if (!hasFetchedData && !noRequest) {
      fetchChildren?.(parentIndexes, data.id!).then(result => {
        useFetchedChildren && setChildren(result)
        setHasFetchedData(true)
        forceRender()
      })
    } else if (noRequest) {
      setHasFetchedData(true)
    }
    !childrenAlwaysOpen && setOpen(!open)
  }

  useEffect(() => {
    if (!row.isOpen?.(data) && !childrenAlwaysOpen && !childrenOpenByDefault) {
      setOpen(false)
    } else {
      unwrapChildren()
      setOpen(true)
    }
  }, [childrenAlwaysOpen, row.isOpen, childrenOpenByDefault])

  const renderChildren = () => {
    const childrenToRender = useFetchedChildren ? children : data.children || []
    if (childrenToRender.length && hasFetchedData) {
      return childrenToRender.map((rowData, rowIndex) => (
        <Row
          visibleCells={visibleCells}
          key={`row_${generateKey(rowData, idPath)}`}
          nestedFirst={rowIndex === 0}
          nestedLast={!!data.children?.length && rowIndex === data.children?.length - 1}
          data={rowData}
          row={row}
          onFilterChange={onFilterChange}
          fetchChildren={fetchChildren}
          forceRender={forceRender}
          onRowClick={onRowClick}
          parentIndexes={parentIndexes.concat(rowIndex)}
          type={type}
          idPath={idPath}
          noChildrenRequest={noChildrenRequest}
          expandableType={expandableType}
          rowHeight={rowHeight}
          containerWidth={containerWidth}
          isExpandable={isExpandable}
        />
      ))
    }
    return data.children?.map((_, rowIndex) => (
      <LoadingRow
        backgroundColor={opacity(Token.colorChannel.deepGrey, 0.1)}
        nestedFirst={rowIndex === 0}
        nestedLast={!!data.children?.length && rowIndex === data.children?.length - 1}
        level={level}
        containerWidth={containerWidth}
        rowHeight={rowHeight}
        key={`loading_row_${parentIndexes.join('_')}_${rowIndex}`}
      >
        <Sticky left={0} pl="s-32">
          <Icon type="Spinner" size="small" />
        </Sticky>
      </LoadingRow>
    ))
  }

  const getBackground = () => {
    if (isExpandable) {
      return level === 0 ? Token.color.widgetBackground : Token.color.greyTone2
    }
    if (parentIndexes[0] % 2) {
      return alterBackground ? Token.color.greyTone2 : Token.color.widgetBackground
    }

    return alterBackground ? Token.color.widgetBackground : Token.color.greyTone2
  }

  return (
    <>
      <RowWrapper
        backgroundColor={backgroundColor || getBackground()}
        nestedFirst={nestedFirst}
        nestedLast={!open && nestedLast}
        data-testid={`table_row_${parentIndexes.join('_')}`}
        onClick={(e: React.MouseEvent) => {
          if (row.linkToForm) {
            row.linkToForm(data, parentIndexes)
          }
          if (onRowClick) {
            onRowClick(data, parentIndexes)
          }

          if (row.isChildrenOpener && row.isChildrenOpener(data) && !childrenAlwaysOpen) {
            unwrapChildren(e)
          }
        }}
        type={type}
        style={style}
        rowHeight={rowHeight}
        hasRowAction={!!onRowClick || !!row.linkToForm}
      >
        {visibleCells.map((cell, index) => {
          const dataPoint = cell.dataPoint.replace(/__/g, '.')
          const content = get(data, dataPoint, cell.placeholder || '-')

          const rowPath = parentIndexes.reduce((acc, i) => `${acc}[${i}]`, '')
          const dataPath = `${rowPath}.${dataPoint}`
          /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
          const error = data?.id ? cellErrors?.[data.id]?.[dataPoint] : undefined

          const isFirstCell = index === 0
          const reduceSize =
            (isFirstCell && isNested) || (isFirstCell && level > 0) ? 40 + 16 * level : 0
          return (
            <Cell<T>
              key={`cell_${index}`}
              disabled={row.disabled && row.disabled(data)}
              cell={cell}
              error={error}
              data={data}
              dataPath={dataPath}
              noAutoResize={noAutoResize}
              onFilterChange={onFilterChange}
              type={type}
              reduceSize={reduceSize}
              idPath={idPath}
              parentIndexes={parentIndexes}
              rowHeight={rowHeight}
              prefixContent={
                isFirstCell ? (
                  <>
                    {isUnwrapIconShown && expandableType === 'plus' && (
                      <DropdownWrapper
                        onClick={unwrapChildren}
                        level={level}
                        data-testid={`dropdown_wrapper_${parentIndexes.join('_')}`}
                      >
                        <OpenIcon>
                          <Icon type={iconType} size="tiny" />
                        </OpenIcon>
                      </DropdownWrapper>
                    )}
                    {isUnwrapIconShown && expandableType === 'chevron' && (
                      <DropdownWrapper
                        onClick={unwrapChildren}
                        level={level}
                        data-testid={`dropdown_wrapper_${parentIndexes.join('_')}`}
                      >
                        <Box minWidth={16}>{renderIconTypeChevron()}</Box>
                      </DropdownWrapper>
                    )}
                    {isEmptyWrapperShown && <EmptyWrapper level={level} />}
                  </>
                ) : null
              }
            >
              {isNil(content) ? cell.placeholder || '-' : content}
            </Cell>
          )
        })}
      </RowWrapper>
      {isNested && open ? renderChildren() : null}
    </>
  )
}

export default Row
