import React, { useEffect, useState } from 'react'
import { FilterByInterface, RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable, useIsNewTable } from '@components/TableV2/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  employeeNameColumn,
  employeeStatusColumn,
  employeeTypeColumn,
  lineManagerColumn,
  qualityControlRevolutersColumn,
} from '@src/constants/columns/employee'
import { teamNameColumn } from '@src/constants/columns/team'
import { specialisationRoleNameColumn } from '@src/constants/columns/role'
import { seniorityNameRevolutersColumn } from '@src/constants/columns/seniority'
import { locationNameColumn } from '@src/constants/columns/location'
import { startedAtColumn } from '@src/constants/columns/dates'
import {
  getRevolutersExport,
  getRevolutersItems,
  useGetEmployeesStats,
} from '@src/api/revoluters'
import { RevolutersInterface } from '@src/interfaces/revoluters'
import { FilterButton, MoreBar, TextButton } from '@revolut/ui-kit'
import ExportMenu from '@src/features/ExportMenu/ExportMenu'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import {
  selectAuth,
  selectFeatureFlags,
  selectPermissions,
  selectUser,
} from '@src/store/auth/selectors'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { departmentNameRevolutersColumn } from '@src/constants/columns/department'
import { LOCAL_STORAGE } from '@src/constants/api'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { useGetEmployeeSettings, useGlobalSettings } from '@src/api/settings'
import { useFavouritesFilter } from '@src/features/FavouritesFilter/useFavouritesFilter'
import { withFavourites } from '@src/features/FavouritesFilter/withFavourites'
import { StatFilters } from '@components/StatFilters/StatFilters'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import { capitalize } from 'lodash'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { APPLICATIONS } from '@src/constants/hub'
import { getAppOrTab } from '@src/features/HubAppIcon/utils'
import {
  SHOW_EXTERNAL_STAT_FILTERS,
  StatFilterId,
  getDefaultStatsFilter,
  getEmployeeTypeFilters,
  hasEmployeeTypeFilter,
} from './filters'
import Table from '@components/TableV2/Table'

export const STATUSES: { [key in StatFilterId]: string } = {
  active_employees: 'active',
  pending_employees: 'pending',
  onboarding_employees: 'onboarding',
  hired_employees: 'hired',
  active_employees_external: 'active',
}

const Row: RowInterface<RevolutersInterface> = {
  linkToForm: data =>
    navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: data.id })),
  cells: [
    {
      ...employeeNameColumn,
      width: 200,
    },
    {
      ...employeeTypeColumn,
      width: 110,
    },
    {
      ...departmentNameRevolutersColumn,
      width: 160,
    },
    {
      ...teamNameColumn,
      width: 220,
    },
    {
      ...specialisationRoleNameColumn,
      width: 200,
    },
    {
      ...seniorityNameRevolutersColumn,
      width: 110,
    },
    {
      ...lineManagerColumn,
      width: 200,
    },
    {
      ...qualityControlRevolutersColumn,
      width: 200,
    },
    {
      ...locationNameColumn,
      width: 120,
    },
    {
      ...startedAtColumn,
      width: 120,
    },
    {
      ...employeeStatusColumn,
      width: 120,
    },
  ],
}

type Props = {
  onClickBulkEdit: () => void
}
const EmployeeTable = ({ onClickBulkEdit }: Props) => {
  const user = useSelector(selectUser)
  const { subscriptionState } = useSelector(selectAuth)
  const isNewTable = useIsNewTable()
  const isAccountClosing = subscriptionState === 'closing'

  const [selectedStatFilter, setSelectedStatFilter] = useState<StatFilterId | undefined>(
    getDefaultStatsFilter(),
  )
  const [includeExternal, setIncludeExternal] = useState(!hasEmployeeTypeFilter())

  const [showMyReports, setShowMyReports] = useLocalStorage(
    LOCAL_STORAGE.SHOW_MY_REPORTS,
    false,
  )
  const { data: employeeSettings } = useGetEmployeeSettings()
  const {
    settings: { benefits_enabled, total_compensation_enabled },
  } = useGlobalSettings()
  const { FavouritesFilter, initialFilter: initialFavouritesFilter } =
    useFavouritesFilter('employee')
  const isCommercial = useIsCommercial()

  const getFilterByLineManager = (setFilter: boolean) => ({
    filters: setFilter
      ? [
          {
            name: user.display_name,
            id: user.id,
          },
        ]
      : [],
    columnName: 'line_manager__id',
    nonResettable: true,
  })

  const sortBy: any = [
    {
      sortBy: 'status',
      direction: SORT_DIRECTION.DESC,
      nonResettable: true,
    },
    {
      sortBy: 'employee_type',
      direction: SORT_DIRECTION.DESC,
      nonResettable: true,
    },
    {
      sortBy: 'full_name',
      direction: SORT_DIRECTION.DESC,
      nonResettable: true,
    },
  ]

  const generateFilters = (id?: StatFilterId) => {
    const filters = []

    if (id) {
      filters.push(getEmployeeTypeFilters(id, includeExternal))
      const status = STATUSES[id]
      filters.push({
        filters: [
          {
            name: capitalize(status),
            id: status,
          },
        ],
        columnName: 'status',
        nonResettable: false,
      })
    }

    if (showMyReports) {
      filters.push(getFilterByLineManager(true))
    }

    if (initialFavouritesFilter) {
      filters.push(initialFavouritesFilter)
    }

    return filters
  }

  const table = useTable<RevolutersInterface>(
    { getItems: getRevolutersItems },
    generateFilters(getDefaultStatsFilter()),
    sortBy,
  )
  const onFilterChanged: typeof table.onFilterChange = (...args) => {
    const [filter] = args
    const isStatusOrTypeFilter = ({ columnName }: FilterByInterface) =>
      columnName === 'employee_type' || columnName === 'status'
    if (
      (Array.isArray(filter) && filter.find(isStatusOrTypeFilter)) ||
      (!Array.isArray(filter) && isStatusOrTypeFilter(filter))
    ) {
      setSelectedStatFilter(undefined)
    }

    return table.onFilterChange(...args)
  }
  const permissions = useSelector(selectPermissions)
  const featureFlags = useSelector(selectFeatureFlags)

  const canAdd = permissions.includes(PermissionTypes.AddEmployees)
  const canBulkEdit = permissions.includes(PermissionTypes.BulkEditEmployees)
  const canImportEmployees = permissions.includes(PermissionTypes.AddEmployeeUpload)
  const canImportExternalEmployees = permissions.includes(
    PermissionTypes.AddEmployeeUploadingSession,
  )
  const canImport =
    !!employeeSettings?.enable_import_data &&
    (canImportExternalEmployees || canImportEmployees)
  const canExport = !!employeeSettings?.enable_export_csv_xls
  const canViewBenefits =
    !!benefits_enabled && permissions.includes(PermissionTypes.ViewBenefitsTemplate)
  const compensationTab = getAppOrTab(permissions, APPLICATIONS.compensation)
  const canViewCompensation = !!total_compensation_enabled && !!compensationTab
  const canViewEmployeePreferences = permissions.includes(
    PermissionTypes.ViewEmployeePreferences,
  )
  const canViewContracts = permissions.includes(PermissionTypes.ViewContractsTab)
  const canViewScreening = permissions.includes(PermissionTypes.ViewAllScreeningChecks)
  const lifecycleTab = getAppOrTab(permissions, APPLICATIONS.lifecycle)
  const keyPersonsTab = getAppOrTab(permissions, APPLICATIONS.keyPersons)

  const onToggleMyReports = () => {
    setShowMyReports(!showMyReports)
    table.onFilterChange(getFilterByLineManager(!showMyReports))
  }

  useEffect(() => {
    statFiltersOnClick(selectedStatFilter)
  }, [includeExternal])

  const filterQuery = filterSortPageIntoQuery(table.sortBy, table.filterBy, 1)

  const { data: stats } = useGetEmployeesStats()

  const StatFiltersConfig = [
    {
      id: 'active_employees',
      title: 'Active',
      value: stats?.active_employees,
    },
    {
      id: 'hired_employees',
      title: 'Hired',
      value: stats?.hired_employees,
    },
    {
      id: 'onboarding_employees',
      title: 'Onboarding',
      value: stats?.onboarding_employees,
    },
    {
      id: 'pending_employees',
      title: 'Pending',
      value: stats?.pending_employees,
    },
    {
      id: 'active_employees_external',
      title: 'External',
      value: stats?.active_employees_external,
    },
  ]

  const statFiltersOnClick = (id?: StatFilterId) => {
    const filters = generateFilters(id)
    table.onFilterChange(filters)
    setSelectedStatFilter(id)
    return filters
  }

  const minActionsNumToShow = 1
  const moreBarCount =
    minActionsNumToShow +
    Number(Boolean(canAdd)) +
    Number(Boolean(canBulkEdit)) +
    Number(Boolean(canViewEmployeePreferences))

  const isNewImportFlow =
    isCommercial || featureFlags.includes(FeatureFlags.EditableEmployeesTable)

  const bulkImportActionUrl = pathToUrl(
    isNewImportFlow
      ? ROUTES.APPS.EMPLOYEES.BULK_UPLOAD_EMPLOYEES.SELECT_FLOW
      : ROUTES.FORMS.EMPLOYEES_BULK_IMPORT,
  )
  const bulkEditActionProps = isNewImportFlow
    ? { onClick: onClickBulkEdit }
    : {
        use: InternalLink,
        to: pathToUrl(ROUTES.FORMS.BULK_EDIT_EMPLOYEES.SELECT),
      }

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <StatFilters
          filters={StatFiltersConfig}
          onClick={id => statFiltersOnClick(id as StatFilterId)}
          selectedFilter={selectedStatFilter}
        />
      </Table.Widget.Info>
      <Table.Widget.Search>
        <Table.Search
          placeholder="Search by name or title"
          onFilter={table.onFilterChange}
        />
      </Table.Widget.Search>
      <Table.Widget.Actions>
        <MoreBar maxCount={moreBarCount}>
          {canAdd && (
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE.NEW)}
              useIcon="Plus"
              disabled={isAccountClosing}
            >
              Add employee
            </MoreBar.Action>
          )}
          {canBulkEdit && (
            <MoreBar.Action useIcon="Pencil" {...bulkEditActionProps}>
              Edit
            </MoreBar.Action>
          )}
          <MoreBar.Action
            to={pathToUrl(ROUTES.FORMS.EMPLOYEES_ORG_CHART)}
            use={InternalLink}
            useIcon="People"
          >
            View org chart
          </MoreBar.Action>
          {canViewEmployeePreferences && (
            <MoreBar.Action
              to={pathToUrl(ROUTES.SETTINGS.EMPLOYEES.GENERAL)}
              use={InternalLink}
              useIcon="Gear"
            >
              Settings
            </MoreBar.Action>
          )}
          {canViewBenefits && (
            <MoreBar.Action
              to={pathToUrl(ROUTES.APPS.BENEFITS.ENROLMENT)}
              use={InternalLink}
              useIcon="Merchant"
            >
              Manage Benefits
            </MoreBar.Action>
          )}
          {canViewCompensation && (
            <MoreBar.Action
              to={pathToUrl(compensationTab.url)}
              use={InternalLink}
              useIcon="CreditBag"
            >
              Manage Compensation
            </MoreBar.Action>
          )}
          {!!lifecycleTab && (
            <MoreBar.Action
              to={pathToUrl(lifecycleTab.url)}
              use={InternalLink}
              useIcon="Rocket"
            >
              Manage Lifecycle
            </MoreBar.Action>
          )}
          {canViewContracts && (
            <MoreBar.Action
              to={pathToUrl(ROUTES.APPS.CONTRACTS.ALL)}
              use={InternalLink}
              useIcon="Pencil"
            >
              Manage Contracts
            </MoreBar.Action>
          )}
          <HideIfCommercial>
            {!!keyPersonsTab && (
              <MoreBar.Action
                to={pathToUrl(keyPersonsTab.url)}
                use={InternalLink}
                useIcon="ArrowRightLeft"
              >
                Manage Key Persons
              </MoreBar.Action>
            )}
          </HideIfCommercial>
          {canViewScreening && (
            <MoreBar.Action
              to={pathToUrl(ROUTES.APPS.SCREENING.SCREENING_TABLE)}
              use={InternalLink}
              useIcon="Search"
            >
              Screening
            </MoreBar.Action>
          )}
          {canImport && (
            <MoreBar.Action
              disabled={isAccountClosing}
              to={bulkImportActionUrl}
              use={InternalLink}
              useIcon="ShareIOs"
            >
              Import data
            </MoreBar.Action>
          )}
          {canExport && (
            <ExportMenu
              fileName="Employees"
              request={getRevolutersExport}
              filterQuery={filterQuery}
            />
          )}
          <Table.ColumnsSettingsButton />
        </MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Filters>
        {isCommercial &&
          selectedStatFilter &&
          SHOW_EXTERNAL_STAT_FILTERS.includes(selectedStatFilter) && (
            <FilterButton
              onClick={() => {
                setIncludeExternal(prev => !prev)
              }}
              active={includeExternal}
            >
              {includeExternal ? 'Exclude external' : 'Include external'}
            </FilterButton>
          )}
        <FilterButton onClick={onToggleMyReports} active={showMyReports}>
          My reports
        </FilterButton>
        <FavouritesFilter table={table} />
      </Table.Widget.Filters>
      <Table.Widget.Table>
        <AdjustableTable<RevolutersInterface>
          name={TableNames.Employees}
          useWindowScroll
          dataType="Employee"
          row={Row}
          {...table}
          onFilterChange={onFilterChanged}
          noDataMessage={
            showMyReports ? (
              <>
                No reports found.{' '}
                <TextButton onClick={onToggleMyReports}>
                  Click here to remove this filter.
                </TextButton>
              </>
            ) : (
              'Employees will appear here.'
            )
          }
          hideCount={!!isNewTable}
          tableSettings={
            isNewTable
              ? {
                  visible: [],
                  hidden: [
                    employeeTypeColumn.title,
                    departmentNameRevolutersColumn.title,
                    startedAtColumn.title,
                    locationNameColumn.title,
                    qualityControlRevolutersColumn.title,
                  ],
                }
              : undefined
          }
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default withFavourites<Props>(EmployeeTable)
